import { render, staticRenderFns } from "./Plaza.vue?vue&type=template&id=27d67b0e&scoped=true&"
import script from "./Plaza.vue?vue&type=script&lang=ts&"
export * from "./Plaza.vue?vue&type=script&lang=ts&"
import style0 from "./Plaza.vue?vue&type=style&index=0&id=27d67b0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d67b0e",
  null
  
)

export default component.exports